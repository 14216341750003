import React from "react"
import { graphql } from "gatsby"
import Nav from "../components/nav"
import Footer from "../components/footer"
import { Link } from "gatsby"
import ArrowRight from "../components/icons/arrowRight"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PressBMWRetailNext = ({ data,location }) => {
  const params = new URLSearchParams(location.search);
  var url_param = location.search;
  var url_param2;

  var utm_campaign = params.get("utm_campaign");
  var utm_source = params.get("utm_source");
  var utm_medium = params.get("utm_medium");
  var utm_content = params.get("utm_content");

  if(url_param){
    url_param = url_param.replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/\?/g, '&');
  }

  if(url_param){
    url_param = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/\?/g, '&');
  }

  if(utm_campaign) {
    utm_campaign = utm_campaign.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_campaign = ""
  }
  if(utm_source) {
    utm_source = utm_source.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_source = ""
  }
  if(utm_medium) {
    utm_medium = utm_medium.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_medium = ""
  }
  if(utm_content) {
    utm_content = utm_content.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_content = ""
  }

  var url_param3 = "?utm_campaign="+utm_campaign+"&utm_source="+utm_source+"&utm_medium="+utm_medium+"&utm_content="+utm_content
  
  return (
    <Layout>
      <Nav header="nomenu" urlParam={url_param}/>
      <div className="no-masthead relative"></div>
      <section className="section sectionFirst">
        <div className="container py-5">
          <div className="about-description">
          <br /><br />
          <h2 className="text-xl md:text-3xl xl:text-4xl font-light uppercase mb-10">BMW RETAIL.NEXT</h2>
            
            <p className="mb-5 lg:pr-5">
            BMW Retail.Next, a new retail concept for all BMW Group brands, is designed to provide a holistic experience for customers throughout every online or offline interaction they have with the dealership. It not only includes a design overhaul, but new digital technologies and new customer facing roles and processes that will provide every customer with a best-in-class premium experience. From the moment a customer engages a dealership, he/she can expect personalised interactions, connected experiences and proactive service across all channels. We aim to deliver them the ultimate retail experience.
            </p>
            <p className="mb-5 lg:pr-5">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.press1
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </p>
            <p className="mb-5 lg:pr-5">
            The new BMW Eurokars Experience Centre spans nearly 796m<sup>2</sup> and is the first Retail.Next concept space in Singapore. In addition to a brand-new stylish interior, the space boasts multi-functional areas, as well as shared and open spaces, with the customer’s comfort and experience in mind. The warm and inviting environment allows customers to explore our latest vehicles or lifestyle collection, or comfortably wait for their vehicles to be serviced. New digital technologies, such as Emotional Virtual Experience (EVE) and POS.Digital, are seamlessly integrated into the space to engage customers and inform them of the latest products and services.
            </p>
            <p className="mb-5 lg:pr-5">
            This latest concept is a testament to everything that the BMW Group stands for – aspirational, premium design, innovation, digitalisation, professional teams, and a superior customer experience befitting a luxury brand.
            </p>
            <h3 className="mb-3 text-l md:text-2xl xl:text-2xl"><strong>The Eurokars Experience.</strong></h3>
            
            <p className="mb-5 lg:pr-5">A warm, progressive and inviting environment has been created to cater to the customers’ wishes and needs, from the moment they enter the Centre.<br /><br />
            <ul className="list-disc list-outside px-5">
              <li className="mb-3 px-2">An integrated Welcome and Customer Consultation area provides convenience and comfort for all customers to explore their desired car. If they prefer more privacy, a Sales Lounge is also available.</li>
              <li className="mb-3 px-2">Customer consultation is carried out, with the help of 3D digital configuration tools, allowing customers to visualise their future BMW, interior and exterior.</li>
              <li className="mb-3 px-2">10 cars can be displayed at any one time in the Experience Centre, with a selection of high-performance models in the dedicated BMW M area, or Luxury Class models.</li>
              <li className="mb-3 px-2">The Multifunction Bay can be used as an exclusive space for vehicle handovers, VIP consultations, presentations, or exclusive new product launches.</li>
              <li className="mb-3 px-2">Various seating arrangements throughout the Experience Centre accommodate customer’s needs, such as working while waiting for their car to be serviced.</li>
              <li className="mb-3 px-2">The BMW Café and Customer Hospitality area, exudes a relaxed ambience and comes with a wide selection of refreshments.</li>
              <li className="mb-3 px-2">Suspended ceiling elements come in contrasting shapes and structures to highlight points of interest to the customer.</li>
              <li className="mb-3 px-2">Elements such as plants and green walls underline the brand’s sustainability and electrification credentials.</li>
              
            </ul>
            </p>

            <h3 className="mb-3 text-l md:text-2xl xl:text-2xl"><strong>Digitalisation.</strong></h3>
            <p className="mb-5 lg:pr-5">
            The customer experience is further enhanced by the availability of digital tools, such as the Emotional Virtual Experience (EVE), a 3D-rendering software that presents vehicles in almost photo-realistic quality during the consultation and sales process. Thanks to EVE, customers can view their configured vehicle and explore it from all available angles, including a detailed look at its interior and exterior and experience it in different environments during the day and night.
            </p>
          
            <h3 className="mb-3 text-l md:text-2xl xl:text-2xl"><strong>Customer Support.</strong></h3>
            <p className="mb-5 lg:pr-5">
            A total of eight Mechanical and Electrical (M+E) work bays are also available, with the latest equipment and tools standardised by BMW, providing even higher levels of service convenience to customers. To support existing and new EV owners, six dedicated charging points are also located in the customer parking area.<br /><br />The BMW Eurokars Experience Centre is located at 11 Kung Chong Road, Singapore 159147.
            </p>
            
            <br />
            
              
            <p className="text-neutral-400 hover:text-black flex float-right">
              <span className="w-5 -ml-1.5 ">
                <ArrowRight />
              </span>
              <span className="ml-1 font-bold text-black md:transition-colors md:duration-150 md:text-neutral-400 md:hover:text-black">
              <Link
                  to={"/press/" + url_param}
                  data-gtm-category="BMW Press Release"
                  data-gtm-action="Clicked_Link"
                  data-gtm-label="View Press Release">
                  <strong>Back to Press Hub</strong>
                </Link>
              </span>
            </p>
              
            <br /><br />
            
            <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
            <p className="mb-5 lg:pr-5">
            <strong><u>Media Enquiries</u></strong><br /><br />
            <strong>Luke Pereira</strong><br />
            Senior Marketing & Brand Communications Executive<br />
            BMW Eurokars Auto<br />
            Email: luke.pereira@eurokars.com.sg <br />
            </p>
            <br /><br />
            

            
            
          </div>
          <div className="showroom-contact mt-8 md:mt-12">
            <h2 className="text-l md:text-2xl xl:text-3xl font-light uppercase mb-3">
              Retail Locations
            </h2>
            <div className="showroom-contact-details">
              <div className="grid grid-cols-1 md:grid-cols-12 border-t md:border-0 border-neutral-200">
                <div className="md:col-span-3 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Location:
                </div>
                <div className="md:col-span-4 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Address:
                </div>
                <div className="md:col-span-5 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Opening Hours:
                </div>

                <div className="md:col-span-3 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Location:
                  </span>
                  <span className="font-bold">BMW Eurokars Experience Centre</span>
                 
                </div>
                <div className="md:col-span-4 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Address:
                  </span>
                  11 Kung Chong Road, Singapore 159147 
                </div>
                <div className="md:col-span-5 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Opening Hours:
                  </span>
                  Monday &ndash; Saturday: 8:30am &ndash; 7pm <br />
                  Sunday & Public Holidays: 10am &ndash; 6pm 
                </div>
                <div className="md:col-span-3 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Location:
                  </span>
                  <span className="font-bold">BMW Eurokars Auto Showroom</span>
                 
                </div>
                <div className="md:col-span-4 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Address:
                  </span>
                  11 Leng Kee Road, Singapore 159091  
                </div>
                <div className="md:col-span-5 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Opening Hours:
                  </span>
                  Monday &ndash; Saturday: 8:30am &ndash; 7pm <br />
                  Sunday & Public Holidays: 10am &ndash; 6pm 
                </div>
              </div>
            </div>
          </div>
          <div className="showroom-contact mt-8 md:mt-12">
            <h2 className="text-l md:text-2xl xl:text-3xl font-light uppercase mb-3">
              Service Locations
            </h2>
            <div className="showroom-contact-details">
              <div className="grid grid-cols-1 md:grid-cols-12 border-t md:border-0 border-neutral-200">
                <div className="md:col-span-3 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Location:
                </div>
                <div className="md:col-span-4 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Address:
                </div>
                <div className="md:col-span-5 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Opening Hours:
                </div>

                <div className="md:col-span-3 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Location:
                  </span>
                  <span className="font-bold">BMW Eurokars Authorised Service Centre </span>
                  
                </div>
                <div className="md:col-span-4 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Address:
                  </span>
                  11 Kung Chong Road, Singapore 159147 
                </div>
                <div className="md:col-span-5 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Opening Hours:
                  </span>
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div>
                    Monday &ndash; Friday: 8am &ndash; 6pm <br />
                    Saturday: 8am &ndash; 12pm <br />
                    Sunday & Public Holidays: Closed
                    </div>
                    
                  </div>
                  
                </div>
                <div className="md:col-span-3 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Location:
                  </span>
                  <span className="font-bold">BMW Eurokars Authorised Service Centre</span>
                  
                </div>
                <div className="md:col-span-4 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Address:
                  </span>
                  29 Leng Kee Road, Singapore 159099 
                </div>
                <div className="md:col-span-5 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Opening Hours:
                  </span>
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div>
                    Monday &ndash; Friday: 8am &ndash; 6pm <br />
                    Saturday: 8am &ndash; 12pm <br />
                    Sunday & Public Holidays: Closed
                    </div>
                    
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer urlParam={url_param} />
    </Layout>
  )
}
export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { page_id: { eq: "press_bmw_retail_next" } } }) {
      edges {
        node {
          frontmatter {
            slug
            page_id
            date
            title
            description
            masthead_line1
            masthead_line2
            masthead_desktop {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            masthead_mobile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 150 }
                  placeholder: BLURRED
                  breakpoints: [650]
                  outputPixelDensities: [2]
                )
              }
            }

            press1 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            press2 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            press3 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            press4 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            press5 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
          }
        }
      }
    }
  }
`

export default PressBMWRetailNext

const seoDetails = {
  title: "Eurokars Auto | BMW Eurokars Press Hub - BMW Retail.Next",
  description:
    "As an official dealer of BMW, Eurokars Auto offers the full range of BMW vehicles – from fully electric BMW i models to high-performance BMW M models.",
  keywords: "Eurokars, Eurokars Auto, BMW, BMW Eurokars Auto",
  image: "og-eka-about-us.jpg",
}

export const Head = () => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>

  </>
)
